.SearchView header,
.SearchView header input,
.SearchView .results {
  transition: all 0.5s ease;
}

.SearchView header {
  position: relative;
}

.SearchView header input {
  margin-left: 32px;
}

.SearchView .results {
  pointer-events: none;
  opacity: 0;
}

.SearchView.hasResults .results {
  pointer-events: auto;
  opacity: 1;
}
