.Playback .NowPlaying {
  display: grid;
  grid-template: auto / auto 1fr;
  grid-column-gap: 16px;
}

.Playback .NowPlaying .cover-art {
  height: 80px;
  width: 80px;
}

.Playback .NowPlaying .Image {
  grid-row: 1 / 4;
  grid-column: 1 / 2;
  height: 100%;
  width: 100%;
}

.Playback .NowPlaying .Artists {
  font-size: 0.8em;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  align-self: end;
}

.Playback .NowPlaying .trackName {
  font-size: 24px;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  line-height: 1;
}
