.PlayableList a {
  color: #fff;
}

.PlayableList .items {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin: -48px -16px;
}

.PlayableList .item {
  height: 16em;
  margin: 48px 16px;
  perspective: 200px;
  width: 16em;
}

.PlayableList .item > div {
  transform-origin: 50px 50px;
  transition: all 0.2s ease;
  z-index: 1;
}

.PlayableList .image {
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 1em 2em -0.5em #000;
}

.PlayableList .item:hover > div {
  z-index: 2;
}

.PlayableList .playback {
  display: grid;
  opacity: 0;
}

.PlayableList .playback .PlayButton {
  background-color: rgba(0, 0, 0, 0.3);
  border: solid 2px #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  height: 100px;
  margin: auto;
  opacity: 0.8;
  width: 100px;
  z-index: 2;
}

.PlayableList .playback .PlayButton .icon {
  background-position: calc(50% + 4px) 50%;
  background-size: 38%;
}

.PlayableList .playback button:hover {
  opacity: 1;
}

.PlayableList .item > *:hover .playback {
  opacity: 1;
}

.PlayableList .placeholder {
  border: solid 1px #0001;
}
