.PlayerUI {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: hidden;
}

.PlayerUI > .viewport {
  display: grid;
  grid-template-columns: min-content 1fr;
}

.PlayerUI > .viewport > main {
  overflow: hidden;
  position: relative;
}

.PlayerUI > .viewport > main > .content {
  inset: 0;
  overflow: auto;
  position: absolute;
}

.PlayerUI > .viewport > main > .content::-webkit-scrollbar {
  width: 8px;
}

.PlayerUI > .viewport > main > .content::-webkit-scrollbar-track {
  background: #00000000;
}

.PlayerUI > .viewport > main > .content::-webkit-scrollbar-thumb {
  background: #ffffff40;
}

.PlayerUI > .viewport > nav {
  background: #040404ee;
  padding: 16px;
  width: 160px;
}

.PlayerUI > .viewport::-webkit-scrollbar {
  width: 8px;
}

.PlayerUI > .viewport::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}

.PlayerUI .compass {
  background: #0005;
  border-radius: 0 0 16px 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 32px;
  padding: 8px 32px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
}

.PlayerUI .compass a {
  color: #fff;
}
