.QuickSearch {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateX(-50%) translateY(-50%) rotateX(90deg);
  transform-origin: center;
  z-index: 1;
}

.QuickSearch.isVisible {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) rotateX(0);
}

.QuickSearch input {
}
