.TrackList .Track {
  align-items: start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: grid;
  font-size: 16px;
  grid: auto auto / 48px 1fr 1fr auto;
  grid-gap: 4px;
  line-height: 1.4;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.TrackList .Track:nth-child(even) {
  background: rgba(0, 0, 0, 0.6);
}

.TrackList .Track:nth-child(odd) {
  background: rgba(0, 0, 0, 0.65);
}

.TrackList .Track:hover {
  background: rgba(0, 0, 0, 0.7);
}

.TrackList .Track .name {
  grid-row: 1 / 2;
}

.TrackList .Track .playback {
  align-self: center;
  grid-column: 1 / 1;
  grid-row: 1 / end;
  justify-self: start;
}

.TrackList .Track .playback .PlayButton {
  background: none;
  border: 2px solid #fff;
  border-radius: 50%;
  display: block;
  font-size: 0;
  opacity: 0.6;
  height: 28px;
  width: 28px;
}

.TrackList .Track .playback .PlayButton .icon {
  height: 12px;
  margin: auto;
  margin-left: 1px;
  width: 12px;
}

.TrackList .Track:hover .playback .PlayButton {
  opacity: 1;
}

.TrackList .Track .album {
  grid-column: 2 / 2;
  grid-row: 2 / 2;
}

.TrackList .Track .artists {
  font-size: 0.9em;
}
