.PlaylistList .item {
  font-size: 16px;
  line-height: 1.2;
}

.PlaylistList .item .Playlist {
  transition: opacity 1s;
}

.PlaylistList .item.pending .Playlist {
  opacity: 0;
}

.PlaylistList .Playlist {
  display: grid;
  grid-row-gap: 12px;
}

.PlaylistList .Playlist .image {
  height: 16em;
  width: 16em;
}

.PlaylistList .Playlist a,
.PlaylistList .Playlist .image {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.PlaylistList .Playlist .Image {
  height: 100%;
  width: 100%;
}

.PlaylistList .Playlist .playback {
  grid-column: 1;
  grid-row: 1;
}

.PlaylistList .Playlist .name,
.PlaylistList .Playlist .owner {
  transition: opacity 0.2s;
  text-align: left;
  text-shadow: 0 0.2em 0.4em #000;
}

.PlaylistList .Playlist .owner {
  font-weight: 400;
  margin-top: -4px;
}

.PlaylistList .item:hover .Playlist .name,
.PlaylistList .item:hover .Playlist .owner {
  opacity: 1;
}

.PlaylistList .item .placeholder {
  background: #0002;
  height: 16em;
  width: 16em;
}
