html,
body,
#root {
  height: 100%;
}

body {
  background: #000;
  color: #ddd;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body,
input,
button {
  font-family: "Cairo", sans-serif;
  font-weight: bold;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  margin: 0;
}

a {
  color: #fff;
  transition: color 10s;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  border: none;
  background: #ddd;
  color: black;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.1em;
  outline: none;
  text-transform: uppercase;
}

button:hover {
  background: black;
  color: #ddd;
}

input {
  background: rgba(0, 0, 0, 0.5);
  border: solid 2px #ddd;
  color: #fff;
  outline: none;
  transition: border-color 10s;
}

.QuickSearch input,
.SearchView input {
  border-radius: 2em;
  text-align: center;
  padding: 0.25em 0.5em;
  width: 14em;
}
