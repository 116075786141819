.Welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.Welcome h2 {
    display: flex;
    align-items: center;
}

.Welcome .join {
    text-align: center;
}

.Welcome .authorize {
    margin: 2em;
}

.Welcome .authorize button {
    font-size: 1.4em;
}
