.Playback {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 2em #000;
  display: grid;
  grid: 1em auto / 1fr 300px 1fr;
  padding: 16px;
  transition: background-color 5s;
}

.Playback .Scrubber {
  grid-column: 1 / end;
  grid-row: 1 / 1;
}

.Playback .vis {
  justify-self: end;
}

.Playback .Time {
  font-size: 24px;
  line-height: 1;
}
