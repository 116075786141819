.App {
  height: 100%;
}

.ProgressBar {
  background: rgba(0, 0, 0, 0.4);
}

.ProgressBar .progress {
  background: #eee;
  height: 0.3em;
}

header h2 {
  color: #eee;
  font-size: 2em;
}
