.Image {
    height: 10em;
    width: 10em;
}

.Image > img {
    animation: fade-in 0.5s;
    object-fit: contain;
    height: 100%;
    width: 100%;
}
