.Playback .Scrubber {
    cursor: pointer;
}

.Playback .Sections {
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
}

.Playback .Sections.ready {
    opacity: 0.2;
}

.Playback:hover .Sections.ready {
    opacity: 1;
}

.Playback .Sections .section {
    border-left: 1px solid #fff;
    height: 4px;
}
