.PlayerWindow {
  height: 100%;
  position: relative;
}

.PlayerWindow .PlayerUI {
  inset: 0;
  position: absolute;
  z-index: 2;
}

.PlayerWindow .Visuals {
  inset: 0;
  position: absolute;
  z-index: 1;
}

.PlayerWindow .PlayButton {
  transition: opacity 0.3s ease;
}

.PlayerWindow.pending .PlayButton {
  pointer-events: none;
  opacity: 0;
}
